<template>
  <div class="main row justify-center items-start bg-grey-7 q-gutter-xl" style>
    <q-card flat bordered style="width:210mm; " class="q-mt-xl ">
      <q-card-section class="row justify-between">
        <q-btn
          dense
          outline
          color="primary"
          label="Download PDF"
          :disable="!selSiswa"
          v-can="['WALAS']"
          @click="download"
        />
        <!-- <q-btn dense outline @click="logData"></q-btn> -->
        <div class="row q-gutter-sm">
          <q-select
            dense
            outlined
            v-model="selKelas"
            label="Kelas"
            :options="kelas"
            @input="prosesData"
            style="width:200px"
          />
          <q-select
            dense
            outlined
            v-model="selSiswa"
            label="Siswa"
            :options="siswa"
            style="width:200px"
          />
        </div>
      </q-card-section>
    </q-card>

    <q-card class="paper" v-if="selSiswa">
      <div ref="page">
        <q-card flat class="column justify-between">
          <q-card-section class="column items-center q-px-none">
            <div class="column text-kecil text-center">
              <b>LAPORAN PENILAIAN TENGAH SEMESTER SISWA</b>
              <b v-if="selJenjang == 'MA'"
                >MADRASAH 'ALIYAH SWASTA (MAS) PERGURUAN ISLAM AR RISALAH</b
              >
              <b v-else
                >SEKOLAH MENENGAH PERTAMA (SMP) PERGURUAN ISLAM AR RISALAH</b
              >
              <b>TAHUN PEMBELAJARAN {{ tahunAjar.nama }}</b>
            </div>
            <q-item class="row full-width">
              <q-item-section class="col-8" style="font-size:12px">
                <p class="q-ma-none row" style="height:30px">
                  <a style="width:75px">Nama Siswa</a>
                  <a>
                    :
                    <strong>{{ selSiswa.label }}</strong>
                  </a>
                </p>
                <p class="q-ma-none row" style="height:30px">
                  <a style="width:75px">NIK/NISN</a>
                  <a>: {{ selSiswa.nisn }}</a>
                </p>
              </q-item-section>
              <q-item-section class="col-4" style="font-size:12px">
                <p class="q-ma-none row" style="height:30px">
                  <a style="width:75px">Kelas</a>
                  <a>: {{ selSiswa.nama_kelas }}</a>
                </p>
                <p class="q-ma-none row" style="height:30px">
                  <a style="width:75px">Semester</a>
                  <a>: {{ tahunAjar.semester }}</a>
                </p>
              </q-item-section>
            </q-item>
            <q-markup-table
              class="full-width"
              bordered
              wrap-cells
              flat
              dense
              separator="cell"
            >
              <thead>
                <tr>
                  <th rowspan="2">no</th>
                  <th rowspan="2" class="text-lebih-kecil">MATA PELAJARAN</th>
                  <th rowspan="2" class="text-lebih-kecil">KKM</th>
                  <th colspan="6" class="text-lebih-kecil">
                    NILAI ULANGAN HARIAN dan PENILAIAN TENGAH SEMESTER
                  </th>
                  <th rowspan="2" colspan="2" class="text-lebih-kecil">
                    KETUNTASAN
                  </th>
                </tr>
                <tr>
                  <td class="text-lebih-kecil">UH 1</td>
                  <td class="text-lebih-kecil">UH 2</td>
                  <td class="text-lebih-kecil">UH 3</td>
                  <td class="text-lebih-kecil">UH 4</td>
                  <td class="text-lebih-kecil">PTS</td>
                  <td class="text-lebih-kecil">
                    RERATA
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="val in selSiswa.listMapel" :key="val.id">
                  <td>{{ val.number }}</td>
                  <td style="max-width:100px">{{ val.nama_mapel }}</td>
                  <td>{{ val.kkm }}</td>
                  <td
                    v-for="(el, i) in val.list_uh"
                    :key="i"
                    style="width:50px"
                  >
                    {{ el.rerata }}
                  </td>
                  <td style="width:50px">{{ val.pts }}</td>
                  <td style="width:50px">{{ val.rerata }}</td>
                  <td colspan="2">{{ val.ketuntasan }}</td>
                </tr>
                <tr>
                  <td class="text-center" colspan="8"><b>TOTAL NILAI</b></td>
                  <td>{{ selSiswa.total_nilai }}</td>
                  <td><b>RERATA</b></td>
                  <td>{{ selSiswa.rerata }}</td>
                </tr>
                <tr>
                  <td colspan="10"><b>RATA-RATA KELAS</b></td>
                  <td>{{ rerata_kelas }}</td>
                </tr>
              </tbody>
            </q-markup-table>
            <div class="q-mt-md self-start">
              <p class="text-bold text-italic">Keterangan:</p>
              <p class="">KKM : Kriteria Ketuntasan Minimal</p>
            </div>
          </q-card-section>
          <q-card-section
            horizontal
            class="justify-between q-pb-none"
            style="height:125px"
          >
            <q-card-section class="col-4 text-center ">
              <a>Mengetahui,</a>
              <br />
              <a>Orang Tua / Wali</a>
              <br />
              <br />
              <br />..............................
            </q-card-section>
            <q-card-section class="col-4 text-center ">
              <a>Padang, {{ date }}</a>
              <br />
              <a>Wali Kelas</a>
              <br />
              <br />
              <br />{{ selKelas.nama_walas }}
            </q-card-section>
          </q-card-section>
        </q-card>
      </div>
    </q-card>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import moment from "moment";
import { jsPDF } from "jspdf";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      user: {},
      kelas: [],
      selKelas: null,
      selJenjang: localStorage.getItem("jenjang"),
      tahunAjar: {},
      siswa: [],
      selSiswa: null,
      rerata_kelas: 0,
      date: moment().format("DD MMMM YYYY"),
    };
  },
  async created() {
    this.$q.loading.show({
      message: "Loading Data...",
    });
    await this.getUser();
    await this.getKelas();
    this.$q.loading.hide();
  },
  methods: {
    ...mapGetters(["getCurrentUser"]),
    async getUser() {
      this.user = await this.getCurrentUser();
    },
    async getKelas() {
      let resp = await this.$http.get(
        `/hasil/raport/getKelas/${this.user.id}/${
          this.user.is_kepala_sekolah
        }/${this.user.is_guru_bk}/${
          this.user.is_super_user
        }/${localStorage.getItem("jenjang")}`
      );
      this.kelas = resp.data;
    },
    async prosesData() {
      await this.getTahunAjar();
      await this.prepareData();
    },
    async download() {
      this.$q.loading.show();
      const doc = new jsPDF("p", "mm", "a4");
      let page1 = await html2canvas(this.$refs.page, { scale: 2 });
      const gambar1 = page1.toDataURL("image/png");
      doc.addImage(gambar1, "JPEG", 0, 0, 210, 297, "alias1", "HIGH");
      doc.save("a4.pdf");
      this.$q.loading.hide();
    },
    async getTahunAjar() {
      let resp = await this.$http.get(
        `/hasil/raport_mid/gettahun/${localStorage.getItem("id_tahun_ajaran")}`
      );
      this.tahunAjar = resp.data;
    },
    async prepareData() {
      await this.getSiswa();
      await this.getNilai();
      await this.prepareNilai();
      this.selSiswa = this.siswa[0];
    },
    async getSiswa() {
      let resp = await this.$http.get(
        `/hasil/raport/getdatasiswa/${this.selKelas.value}`
      );
      this.siswa = resp.data;
    },
    async getNilai() {
      let temp = JSON.parse(JSON.stringify(this.siswa));
      let resp = await this.$http.post(
        `/hasil/raport/getnilai/${localStorage.getItem("id_tahun_ajaran")}`,
        this.siswa
      );
      for (let i in temp) {
        temp[i].listMapel = resp.data[i];
      }
      this.siswa = temp;
    },
    prepareNilai() {
      let temp = JSON.parse(JSON.stringify(this.siswa));
      let total_kelas = 0;
      for (let siswa of temp) {
        let num = 0;
        let total_nilai = 0;
        for (let mapel of siswa.listMapel) {
          num++;
          mapel.number = num;
          let total = 0;
          let count = 0;
          for (let item of mapel.list_uh) {
            total = total + parseFloat(item.rerata);
            count++;
          }
          if (mapel.pts) {
            total = total + parseFloat(mapel.pts);
            count++;
          }
          mapel.rerata = parseFloat(total / count).toFixed(2);
          if (count == 0) {
            mapel.rerata = 0;
          }

          total_nilai = total_nilai + parseFloat(mapel.rerata);

          mapel.ketuntasan = "Tuntas";
          if (mapel.rerata < parseFloat(mapel.kkm)) {
            mapel.ketuntasan = "Tidak Tuntas";
          }

          if (mapel.list_uh.length < 4) {
            for (let i = mapel.list_uh.length; i < 4; i++) {
              mapel.list_uh.push({ rerata: null });
            }
          }
        }
        siswa.total_nilai = total_nilai;
        siswa.rerata = parseFloat(total_nilai / num).toFixed(2);
        if (num == 0) {
          siswa.rerata = 0;
        }
        total_kelas = total_kelas + parseFloat(siswa.rerata);
      }
      this.rerata_kelas = parseFloat(total_kelas / this.siswa.length).toFixed(
        2
      );
      this.siswa = temp;
      // console.log(this.siswa);
    },
  },
};
</script>

<style lang="scss">
.text-predikat {
  font-size: 36px !important;
}
.text-lebih-kecil {
  font-size: 10px !important;
}
.text-kecil {
  font-size: 12px !important;
}
.text-besar {
  font-size: 14px !important;
}
.paper {
  width: 210mm;
  height: 297mm;
  padding: 1cm 1cm 1cm 1cm;
}
</style>
